<template>
  <div class="page">
    <IntroData class="section" />
    <TheFooter class="section" />
  </div>
</template>

<script>
import IntroData from "@/components/common/introblock/IntroData.vue";
import TheFooter from "../components/common/footer/TheFooter.vue";
export default {
  components: {
    IntroData,
    TheFooter,
  },
};
</script>
<style
  lang="
    scss"
></style>
