<template>
  <div class="introb1">
    <div class="introb1__wrapper">
      <div class="introb1__content">
        <h1 class="introb1__h1">
          Datenschutz / Sehtest
        </h1>
        <div class="p introb1__p">
          1. Datenschutz auf einen Blick Allgemeine Hinweise Die folgenden
          Hinweise geben einen einfachen Überblick darüber, was mit Ihren
          personenbezogenen Daten passiert, wenn Sie unsere Website besuchen.
          Personenbezogene Daten sind alle Daten, mit denen Sie persönlich
          identifiziert werden können. Ausführliche Informationen zum Thema
          Datenschutz entnehmen Sie unserer unter diesem Text aufgeführten
          Datenschutzerklärung. Datenerfassung auf unserer Website Wer ist
          verantwortlich für die Datenerfassung auf dieser Website? Die
          Datenverarbeitung auf dieser Website erfolgt durch den
          Websitebetreiber. Dessen Kontaktdaten können Sie dem Impressum dieser
          Website entnehmen. Wie erfassen wir Ihre Daten? Ihre Daten werden zum
          einen dadurch erhoben, dass Sie uns diese mitteilen. Hierbei kann es
          sich z.B. um Daten handeln, die Sie in ein Kontaktformular eingeben.
          Andere Daten werden automatisch beim Besuch der Website durch unsere
          IT-Systeme erfasst. Das sind vor allem technische Daten (z.B.
          Internetbrowser, Betriebssystem oder Uhrzeit des Seitenaufrufs). Die
          Erfassung dieser Daten erfolgt automatisch, sobald Sie unsere Website
          betreten. Wofür nutzen wir Ihre Daten? Ein Teil der Daten wird
          erhoben, um eine fehlerfreie Bereitstellung der Website zu
          gewährleisten. Andere Daten können zur Analyse Ihres Nutzerverhaltens
          verwendet werden. Welche Rechte haben Sie bezüglich Ihrer Daten? Sie
          haben jederzeit das Recht unentgeltlich Auskunft über Herkunft,
          Empfänger und Zweck Ihrer gespeicherten personenbezogenen Daten zu
          erhalten. Sie haben außerdem ein Recht, die Berichtigung, Sperrung
          oder Löschung dieser Daten zu verlangen. Hierzu sowie zu weiteren
          Fragen zum Thema Datenschutz können Sie sich jederzeit unter der im
          Impressum angegebenen Adresse an uns wenden. Des Weiteren steht Ihnen
          ein Beschwerderecht bei der zuständigen Aufsichtsbehörde zu. 2.
          Allgemeine Hinweise und Pflichtinformationen Datenschutz Die Betreiber
          dieser Seiten nehmen den Schutz Ihrer persönlichen Daten sehr ernst.
          Wir behandeln Ihre personenbezogenen Daten vertraulich und
          entsprechend der gesetzlichen Datenschutzvorschriften sowie dieser
          Datenschutzerklärung. Wenn Sie diese Website benutzen, werden
          verschiedene personenbezogene Daten erhoben. Personenbezogene Daten
          sind Daten, mit denen Sie persönlich identifiziert werden können. Die
          vorliegende Datenschutzerklärung erläutert, welche Daten wir erheben
          und wofür wir sie nutzen. Sie erläutert auch, wie und zu welchem Zweck
          das geschieht. Wir weisen darauf hin, dass die Datenübertragung im
          Internet (z.B. bei der Kommunikation per E-Mail) Sicherheitslücken
          aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch
          Dritte ist nicht möglich. Hinweis zur verantwortlichen Stelle Die
          verantwortliche Stelle für die Datenverarbeitung auf dieser Website
          ist: Steffen Baumgartl Tobias Wiebeck Rumfordstraße 5 80469 München
          Telefon: 089 / 92583060 E-Mail: hallo@oh-ja.com Verantwortliche Stelle
          ist die natürliche oder juristische Person, die allein oder gemeinsam
          mit anderen über die Zwecke und Mittel der Verarbeitung von
          personenbezogenen Daten (z.B. Namen, E-Mail-Adressen o. Ä.)
          entscheidet. Widerruf Ihrer Einwilligung zur Datenverarbeitung Viele
          Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen
          Einwilligung möglich. Sie können eine bereits erteilte Einwilligung
          jederzeit widerrufen. Dazu reicht eine formlose Mitteilung per E-Mail
          an uns. Die Rechtmäßigkeit der bis zum Widerruf erfolgten
          Datenverarbeitung bleibt vom Widerruf unberührt. Beschwerderecht bei
          der zuständigen AufsichtsbehördeIm Falle datenschutzrechtlicher
          Verstöße steht dem Betroffenen ein Beschwerderecht bei der zuständigen
          Aufsichtsbehörde zu. Zuständige Aufsichtsbehörde in
          datenschutzrechtlichen Fragen ist der Landesdatenschutzbeauftragte des
          Bundeslandes, in dem unser Unternehmen seinen Sitz hat. Eine Liste der
          Datenschutzbeauftragten sowie deren Kontaktdaten können folgendem Link
          entnommen werden: https://www.bfdi.bund.de/. Recht auf
          Datenübertragbarkeit Sie haben das Recht, Daten, die wir auf Grundlage
          Ihrer Einwilligung oder in Erfüllung eines Vertrags automatisiert
          verarbeiten, an sich oder an einen Dritten in einem gängigen,
          maschinenlesbaren Format aushändigen zu lassen. Sofern Sie die direkte
          Übertragung der Daten an einen anderen Verantwortlichen verlangen,
          erfolgt dies nur, soweit es technisch machbar ist. Auskunft, Sperrung,
          Löschung Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen
          jederzeit das Recht auf unentgeltliche Auskunft über Ihre
          gespeicherten personenbezogenen Daten, deren Herkunft und Empfänger
          und den Zweck der Datenverarbeitung und ggf. ein Recht auf
          Berichtigung, Sperrung oder Löschung dieser Daten. Hierzu sowie zu
          weiteren Fragen zum Thema personenbezogene Daten können Sie sich
          jederzeit unter der im Impressum angegebenen Adresse an uns wenden.
          Widerspruch gegen Werbe-Mails Der Nutzung von im Rahmen der
          Impressumspflicht veröffentlichten Kontaktdaten zur Übersendung von
          nicht ausdrücklich angeforderter Werbung und Informationsmaterialien
          wird hiermit widersprochen. Die Betreiber der Seiten behalten sich
          ausdrücklich rechtliche Schritte im Falle der unverlangten Zusendung
          von Werbeinformationen, etwa durch Spam-E-Mails, vor. 3.
          Datenerfassung auf unserer Website Server-Log-Dateien Der Provider der
          Seiten erhebt und speichert automatisch Informationen in so genannten
          Server-Log-Dateien, die Ihr Browser automatisch an uns übermittelt.
          Dies sind: Browsertyp und Browserversion verwendetes Betriebssystem
          Referrer URL Hostname des zugreifenden Rechners Uhrzeit der
          Serveranfrage IP-Adresse Eine Zusammenführung dieser Daten mit anderen
          Datenquellen wird nicht vorgenommen. Die Erfassung dieser Daten
          erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Der
          Webseitenbetreiber hat ein berechtigtes Interesse an der technisch
          fehlerfreien Darstellung und der Optimierung seiner Webseite – hierzu
          müssen die Server-Log-Files erfasst werden. 4. Plugins und Tools
          Google Web Fonts Diese Seite nutzt zur einheitlichen Darstellung von
          Schriftarten so genannte Web Fonts, die von Google bereitgestellt
          werden. Beim Aufruf einer Seite lädt Ihr Browser die benötigten Web
          Fonts in ihren Browsercache, um Texte und Schriftarten korrekt
          anzuzeigen. Zu diesem Zweck muss der von Ihnen verwendete Browser
          Verbindung zu den Servern von Google aufnehmen. Hierdurch erlangt
          Google Kenntnis darüber, dass über Ihre IP-Adresse unsere Website
          aufgerufen wurde. Die Nutzung von Google Web Fonts erfolgt im
          Interesse einer einheitlichen und ansprechenden Darstellung unserer
          Online-Angebote. Dies stellt ein berechtigtes Interesse im Sinne von
          Art. 6 Abs. 1 lit. f DSGVO dar. Wenn Ihr Browser Web Fonts nicht
          unterstützt, wird eine Standardschrift von Ihrem Computer genutzt.
          Weitere Informationen zu Google Web Fonts finden Sie unter
          https://developers.google.com/fonts/faq und in der
          Datenschutzerklärung von Google:
          https://www.google.com/policies/privacy/.Rumfordstraße 5 80469 München
          Vertreten durch: Steffen Baumgartl Tobias Wiebeck Kontakt: Telefon:
          089 / 92 58 30 60 E-Mail: hallo@oh-ja.com Registereintrag: Eintragung
          im Handelsregister. Registergericht: Amtsgericht München
          Registernummer: HRB 222147 Umsatzsteuer:
          Umsatzsteuer-Identifikationsnummer gemäß §27 a Umsatzsteuergesetz:
          DE303718686 Streitschlichtung Die Europäische Kommission stellt eine
          Plattform zur Online-Streitbeilegung (OS) bereit:
          https://ec.europa.eu/consumers/odr. Unsere E-Mail-Adresse finden Sie
          oben im Impressum. Wir sind nicht bereit oder verpflichtet, an
          Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle
          teilzunehmen. Haftung für Inhalte Als Diensteanbieter sind wir gemäß §
          7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen
          Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als
          Diensteanbieter jedoch nicht verpflichtet, übermittelte oder
          gespeicherte fremde Informationen zu überwachen oder nach Umständen zu
          forschen, die auf eine rechtswidrige Tätigkeit hinweisen.
          Verpflichtungen zur Entfernung oder Sperrung der Nutzung von
          Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt.
          Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der
          Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden
          von entsprechenden Rechtsverletzungen werden wir diese Inhalte
          umgehend entfernen. Haftung für Links Unser Angebot enthält Links zu
          externen Websites Dritter, auf deren Inhalte wir keinen Einfluss
          haben. Deshalb können wir für diese fremden Inhalte auch keine Gewähr
          übernehmen. Für die Inhalte der verlinkten Seiten ist stets der
          jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die
          verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche
          Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt
          der Verlinkung nicht erkennbar. Eine permanente inhaltliche Kontrolle
          der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer
          Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von
          Rechtsverletzungen werden wir derartige Links umgehend entfernen.
          Urheberrecht Die durch die Seitenbetreiber erstellten Inhalte und
          Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht. Die
          Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung
          außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen
          Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien
          dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch
          gestattet. Soweit die Inhalte auf dieser Seite nicht vom Betreiber
          erstellt wurden, werden die Urheberrechte Dritter beachtet.
          Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten
          Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden,
          bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von
          Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.en.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
};
</script>

<style lang="scss"></style>
